<template>
  <div>
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span>{{ this.$router.currentRoute.name }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="refulsh"
          >刷新</el-button
        >
      </div>
      <el-row style="width: 600px">
        <el-input placeholder="请输入内容" v-model="search.keyword" size="mini">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchData()"
          ></el-button>
        </el-input>
      </el-row>
      <el-row class="page-top">
        <el-button type="primary" @click="editData(0)" :size="allSize"
          >新增</el-button
        >
      </el-row>
      <el-table
        :data="list"
        style="width: 100%; margin-top: 10px"
        v-loading="loading"
        size="mini"
      >
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="create_time" label="录入时间"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              @click.native.prevent="delData(scope.$index, scope.row.id)"
              type="text"
              size="small"
            >
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-top">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[20, 100, 200, 300, 400]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :title="model_title + '内容'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item
          :label="model_title + '标题'"
          :label-width="formLabelWidth"
          prop="title"
        >
          <el-input v-model="ruleForm.title" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="图片" :label-width="formLabelWidth">
          <el-input
            v-model="ruleForm.pic_path"
            :disabled="true"
            class="el_input"
          ></el-input>
          <el-button-group>
            <el-button>
              <el-upload
                action="/admin/Upload/uploadImage"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              >
                上传
              </el-upload>
            </el-button>
            <el-button
              type="success"
              v-if="ruleForm.pic_path"
              @click="showImage(ruleForm.pic_path)"
              >查看
            </el-button>
            <el-button
              type="danger"
              v-if="ruleForm.pic_path"
              @click="delImage(ruleForm.pic_path, 'pic_path')"
              >删除</el-button
            >
           

          </el-button-group>
           <div class="el-upload__tip">最佳上传750*400</div>
        </el-form-item>
         <el-form-item
          :label="model_title + '排序'"
          :label-width="formLabelWidth"
         
        >
          <el-input v-model="ruleForm.sort" autocomplete="off" type="number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveData()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="图片查看" :visible.sync="dialogVisible" width="30%">
      <el-image :src="show_image"></el-image>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import EditorBar from "/src/components/wangEnduit.vue";
export default {
  name: "list",
  components: { EditorBar },
  data() {
    return {
      allSize: "mini",
      list: [],
      total: 1,
      page: 1,
      size: 20,
      search: {
        keyword: "",
      },
      model_title: "轮播图",
      loading: true,
      isClear: true,
      url: "/banner/",
      info: {},
      dialogFormVisible: false,
      show_image: "",
      dialogVisible: false,
      ruleForm: {
        title: "",
        is_num: 0,
      },
      rules: {
        title: [
          {
            required: true,
            message: "请填写标题",
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "120px",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    change(val) {
      console.log(val);
    },
    editData(id) {
      let _this = this;
      if (id != 0) {
        this.getInfo(id);
      } else {
        this.ruleForm = {
          title: "",
          sort:0,
          pic_path: "",
        };
      }
      _this.dialogFormVisible = true;
    },
    getInfo(id) {
      let _this = this;
      _this.getRequest(_this.url + "read?id=" + id).then((resp) => {
        if (resp) {
          _this.ruleForm = resp.data;
        }
      });
    },
    delData(index, id) {
      this.$confirm("是否删除该信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteRequest(this.url + "delete?id=" + id).then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.list.splice(index, 1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "取消删除!",
          });
        });
    },
    refulsh() {
      this.$router.go(0);
    },
    searchData() {
      this.page = 1;
      this.size = 20;
      this.getData();
    },
    getData() {
      let _this = this;

      _this.loading = true;
      _this
        .postRequest(
          _this.url + "index?page=" + _this.page + "&size=" + _this.size,
          _this.search
        )
        .then((resp) => {
          if (resp.code == 200) {
            _this.list = resp.data;
            _this.total = resp.count;
          }
          _this.loading = false;
        });
    },
    saveData() {
      let _this = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.postRequest(_this.url + "save", this.ruleForm).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: resp.msg,
              });
              this.getData();
              _this.dialogFormVisible = false;
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handleSuccess(res) {
      let _this = this;
      if (res.code == 200) {
        _this.$message.success("上传成功!");
      } else {
        _this.$message.error(res.msg);
      }
      _this.ruleForm.pic_path = res.data.url;
    },
    showImage(file) {
      this.show_image = file;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(file.type);
      if (!isTypeTrue) {
        this.$message.error("上传图片格式不对!");
        return;
      }
      if (this.ruleForm["pic_path"]) {
        this.getRequest(
          "/Upload/delImage?fileName=" + this.ruleForm["pic_path"]
        );
      }
    },
    delImage(file, fileName) {
      let _this = this;
      _this.getRequest("/Upload/delImage?fileName=" + file).then((resp) => {
        if (resp.code == 200) {
          _this.ruleForm[fileName] = "";

          _this.$message.success("删除成功!");
        } else {
          _this.$message.error(resp.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.page-top {
  margin-top: 15px;
}

.el_input {
  width: 475px;
}
</style>
